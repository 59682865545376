import React from "react";
import "./style.scss";

export default function NewsTicker({ data = [] }) {
  // const data = [
  //   // { text: 'NURSERY ADMISSION 2023 - 2024 - First Selected List', link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admission23/First-Selected-List-23.pdf" },
  //   // { text: "NURSERY ADMISSION 2023 - 2024 - Waiting List (65 Pointers)", link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admission23/Nursery-waiting-list-65%20pointer.pdf" },
  //   // {
  //   //   text: "Information for Classes Nursery to IX - Orientation Program for New Admissions Student",
  //   //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/Orientation-Letter-2024.pdf",
  //   // },
  //   // {
  //   //   text: "2nd Waiting List - Pre-school (Nursery) Admission 2024-25",
  //   //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/WAITING-2nd-LIST-211-DT.29-JAN-2024-with-letter.pdf",
  //   // },
  //   // {
  //   //   text: "Selected List - Pre-school (Nursery) Admission 2024-25",
  //   //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/selected-list-pre-school-2024-with-letter.pdf",
  //   // },
  //   // {
  //   //   text: "Waiting List - Pre-school (Nursery) Admission 2024-25",
  //   //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/waiting-list-pre-school-2024-with-letter.pdf",
  //   // },
  //   // {
  //   //   text: "TOTAL REGISTERED APPLICANTS FOR PRE-SCHOOL ADMISSION WITH POINT CRITERIA.",
  //   //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/TOTAL-REGISTERED-APPLICANTS-FOR-PRE-SCHOOL-ADMISSION.pdf",
  //   // },
  //   // {
  //   //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admissions24-25/Nursery-Schedule-for-Entry-Level-Classes.pdf",
  //   //   text: "Admission Schedule - Academic Session 2024-2025",
  //   // },
  //   // {
  //   //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/Information-of-Admission-2023-24-(Circular-regarding-EWS-admission-(High-Court-matter).pdf",
  //   //   text: "View Circular regarding EWS Admission 2023-24",
  //   // },
  //   // {
  //   //   text: "Admissions open for Academic Session 2024-2025",
  //   //   link: "/registrations/",
  //   // },
  // ];

  let ticketItems = [];

  let repeat = 0;
  if (data.length > 0) {
    repeat = Math.ceil(15 / data.length);
  }

  for (let i = 0; i < repeat; i++) {
    data.forEach((item) => {
      ticketItems.push(
        <div key={`news-item-${i + 1}`} className="ticker-item">
          <a
            href={item.link.length > 0 && item.link}
            target="__blank"
            dangerouslySetInnerHTML={{ __html: item.text }}
          ></a>
        </div>
      );
    });
  }

  return (
    <div>
      <div className="tcontainer">
        <div className="ticker-wrap">
          <div className="ticker-move">{ticketItems}</div>
        </div>
      </div>
    </div>
  );
}
