import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
export default function Registrations() {
  return (
    <Layout>
      <SEO
        title="Chinmaya Vidyalaya Delhi Nursery Admissions| Online Registrations | Delhi School"
        description="Chinmaya Vidyalaya vasant vihar Delhi offer Online registrations open for Nursery Admission to class 9th for session 2022-23. Apply now for online vidyalaya registrations."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="chinmaya vidyalaya delhi, online vidyalaya, online registrations nursery,Nursery Admission"
      />
      <div className="registrations">
        <h1 className="heading">Registrations</h1>
        <br />
        <br />
        {/* <p style={{ fontWeight: "bold", textAlign: "center" }}>
          Use of desktop or laptop is recommended to fill in the registration
          form.
        </p> */}
        <div className="row">
          <div className="col col-12 col-lg-6">
            <div className="registrations__card">
              <h2>Online Nursery Registration</h2>
              <div className="registrations__card__date">
                28 November 2024 - 20 December 2024
              </div>
              {/* <a
                target="_blank"
                href="https://cvvvar.iwsportal.com:572/home"
                className="btn-dark"
              >
                Click Here For Registration
              </a> */}
            </div>
          </div>
          <div className="col col-12 col-lg-6">
            <div className="registrations__card">
              <h2>Online Registration for KG – IX</h2>
              <div className="registrations__card__date">
                28 November 2024 Onwards
              </div>
              <a
                href="https://cvvvarother.iwsportal.com:572/home"
                target="_blank"
                className="btn-dark"
              >
                Click Here For Registration
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="tel">
        FOR ADMISSION ENQUIRY CALL -{" "}
        <a href="tel:9999834424">
          <strong>9999834424</strong>
        </a>
      </div>
    </Layout>
  );
}
