import * as React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
// import Placeholder from "../components/Placeholder";
import News from "../components/News2";
import FounderSection from "../components/FounderSection";
import MissionSection from "../components/MissionSection";
import HomePopup from "../components/HomePopup";
import Seo from "../components/seo";
import NewsTicker from "../components/Newsticker";
import AccoladesSection from "../components/AccoladesSection";
import AdmissionEnquiry from "../components/AdmissionEnquiryForm";

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Chinmaya Vidyalaya | CBSE School in Vasant Vihar | Delhi"
        description="Chinmaya Vidyalaya Vasant Vihar is among the best CBSE schools in Delhi under the aegis of the Chinmaya Mission."
        keywords="cbse schools in delhi,best schools in delhi,Chinmaya Vidyalaya Vasant Vihar,chinmaya vidyalaya, CBSE School in Vasant Vihar"
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
      />
      <NewsTicker
        data={[
          {
            text: "Nursery Admission 2025 - 2026 | First List of Selected Children",
            link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admissions-25-26/Selected%20List%20Nursery%202025-26.pdf",
          },
          {
            text: "Nursery Admission 2025 - 2026 | Waiting List",
            link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admissions-25-26/Waiting%20List%20Nursery%202025-26.pdf",
          },
          {
            link: "https://chinmayavvdelhi.ac.in/assets/pdfs/admissions-25-26/Nursery%20Admission%202025-26%20%28Full%20details%29.pdf",
            text: "Nursery Admission (General Category) 2025 - 2026 | Total Forms Received For Nursery Admission with Points Criteria",
          },
          {
            link: "/admissions/",
            text: "Admission for other classes (except Nursery) open for session 2025-26",
          },
        ]}
      />
      <AdmissionEnquiry />
      <HomePopup />
      <Hero />
      <AccoladesSection />
      {/* <News /> */}
      <FounderSection />
      <MissionSection />
    </Layout>
  );
};

export default IndexPage;
