import { Link } from "gatsby";
import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
import { toTitleCase } from "../../../utils/utlls";
import ENewsletterData from "./data";
export default function ENewsletter() {
  const cards = ENewsletterData.map((item) => (
    <a href={item.link} target="_blank" className="enewsletters__cards__card">
      <img className="enewsletters__cards__card__img" src={item.img}></img>
      <div className="enewsletters__cards__card__title">{toTitleCase(item.title)}</div>
    </a>
  ));
  return (
    <Layout>
      <SEO
        title="E-Newsletter  | Chinmaya Vidyalaya"
        description=""
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="enewsletters">
        <h1 className="heading">E-Newsletters</h1>

        <div className="enewsletters__cards">{cards}</div>
      </div>
    </Layout>
  );
}
