const ENewsletterData = [
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/Pre-Primary%20E-Newsletter%20Nov-Dec.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/Pre-Primary%20E-Newsletter%20Nov-Dec.png",
    title: "Pre-Primary E-Newsletter Nov-Dec",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/Primary%20E-Newsletter%20Nov-Dec.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/Primary%20E-Newsletter%20Nov-Dec.png",
    title: "Primary E-Newsletter Nov-Dec",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/Computer-Science-E-Newsletter.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/Computer-Science-E-Newsletter.png",
    title: "Computer Science E-Newsletter 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/MATHEMATICS-E-NEWSLETTER-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/MATHEMATICS-E-NEWSLETTER-2024.png",
    title: "MATHEMATICS E-NEWSLETTER 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRIMARY-E-NEWSLETTER-(OCTOBER-2024).pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRIMARY-E-NEWSLETTER-(OCTOBER-2024).png",
    title: "PRIMARY E-NEWSLETTER October 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRE-PRIMARY-E-NEWSLETTER-(OCTOBER-2024).pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRE-PRIMARY-E-NEWSLETTER-(OCTOBER-2024).png",
    title: "PRE-PRIMARY E-NEWSLETTER October 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/ENGLISH-DEPARTMENT-E-NEWSLETTER-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/ENGLISH-DEPARTMENT-E-NEWSLETTER-2024.png",
    title: "English Department E-Newsletter 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/SEPTEMBER-E-NEWSLETTER-(PRIMARY)-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/SEPTEMBER-E-NEWSLETTER-(PRIMARY)-2024.png",
    title: "September E-Newsletter Primary 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/AUGUST-E-NEWSLETTER-(PRIMARY)-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/AUGUST-E-NEWSLETTER-(PRIMARY)-2024.png",
    title: "August E-Newsletter Primary 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/SEPTEMBER-E-NEWSLETTER-(PRE-PRIMARY)-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/SEPTEMBER-E-NEWSLETTER-(PRE-PRIMARY)-2024.png",
    title: "September E-Newsletter Pre-Primary 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/AUGUST-E-NEWSLETTER-(PRE-PRIMARY)-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/AUGUST-E-NEWSLETTER-(PRE-PRIMARY)-2024.png",
    title: "August E-Newsletter Pre-Primary 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/July-E-Newsletter-Primary-Department.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/July-E-Newsletter-Primary-Department.png",
    title: "July E-Newsletter Primary Department 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/SCIENCE-E-NEWS-LETTER-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/SCIENCE-E-NEWS-LETTER-2024.png",
    title: "Science E-Newsletter 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRE-PRIMARY-E-NEWS-LETTER-JULY-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRE-PRIMARY-E-NEWS-LETTER-JULY-2024.png",
    title: "PRE-PRIMARY E-NEWSLETTER JULY 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/E-News-Letter-(SSC).pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/E-News-Letter-(SSC).png",
    title: "Social Science E-News Letter 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRIMARY-DEPARTMENT-(APRIL-MAY)-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRE-PRIMARY-DEPARTMENT-(APRIL-MAY)-2024.png",
    title: "PRIMARY DEPARTMENT (APRIL-MAY) 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRE-PRIMARY-DEPARTMENT-(APRIL-MAY)-2024.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/PRIMARY-DEPARTMENT-(APRIL-MAY)-2024.png",
    title: "PRE-PRIMARY DEPARTMENT (APRIL-MAY) 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/%E0%A4%B9%E0%A4%BF%E0%A4%82%E0%A4%A6%E0%A5%80-%E0%A4%88-%E0%A4%B8%E0%A4%AE%E0%A4%BE%E0%A4%9A%E0%A4%BE%E0%A4%B0-%E0%A4%AA%E0%A4%A4%E0%A5%8D%E0%A4%B0-2024-25.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/2024/hindi-newsletter.png",
    title: "हिंदी ई-समाचार पत्र 2024-25",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/E-Newsletter-PRIMARY-SEP-OCT-2023.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/primary-enewsletter-sep-oct.png",
    title: "E-Newsletter Primary Sep-Oct 2023",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/E-Newsletter-Pre-Primary-SEP-NOV-2023.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/pre-primary-enewsletter-sep-nov.png",
    title: "E-Newsletter Pre-Primary Sep-Nov 2023",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/MATHEMATICS-E-NEWSLETTER_(2023-2024).pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/maths-e-newsletter-23.png",
    title: "Mathematics E-Newsletter 2023",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/English-E-Newsletter-2023.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/e-newsletter-english-2023.png",
    title: "English E-Newsletter 2023",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/सुघोषः.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/sanskrit-newsletter-2023.png",
    title: "Sanskrit E-Newsletter 2023",
  },
  {
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/science-enewsletter-2023.png",
    title: "Science E-Newsletter 2023",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/SCIENCE-E-NEWSLETTER-2023.pdf",
  },
  {
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/E-newsletter-social-science.png",
    title: "Social Science E-Newsletter",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/E-Newsletter-(Social-Science).pdf",
  },
  {
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/hindi-newsletter-preview.png",
    title: "Hindi E-Newsletter",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/newsletters/%E0%A4%B9%E0%A4%BF%E0%A4%82%E0%A4%A6%E0%A5%80-%E0%A4%88-%E0%A4%B8%E0%A4%AE%E0%A4%BE%E0%A4%9A%E0%A4%BE%E0%A4%B0-%E0%A4%AA%E0%A4%A4%E0%A5%8D%E0%A4%B0.pdf",
  },
  {
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/newsletter-feb-2023.png",
    title: "English E-Newsletter",
    link: "https://online.fliphtml5.com/dpmrw/boyt/",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/Mathematics-E-NewsLetter-0423.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/images/mathnewsletter-2023.png",
    title: "Math E-Newsletter",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/E-News-Letter-Science-2022.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/E-News-Letter-Science-2022-01.jpg",
    title: "Science E-Newsletter",
  },
  {
    link: "https://online.fliphtml5.com/xqzyx/stxf/?1667063543327#p=1",
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/Social%20science%20E-newsletter.jpg",
    title: "Social Science E-Newsletter",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/%E0%A4%B8%E0%A4%AE%E0%A4%BE%E0%A4%9A%E0%A4%BE%E0%A4%B0%E0%A4%AA%E0%A4%A4%E0%A5%8D%E0%A4%B0%E0%A4%AE%E0%A5%8D.pdf",
    img: "https://chinmayavvdelhi.ac.in/assets/images/newsletters/%e0%a4%b8%e0%a4%ae%e0%a4%be%e0%a4%9a%e0%a4%be%e0%a4%b0%e0%a4%aa%e0%a4%a4%e0%a5%8d%e0%a4%b0%e0%a4%ae%e0%a5%8d-01.jpg",
    title: "Sanskrit E-Newsletter",
  },
];

export default ENewsletterData;
